export const colors = {

   purpleBackground: '#220f18',
   deepPurple: '#200f17',
   black: '#000000',
   white: '#ffffff',
   sideBarOrange: '#f53343',
   logoUpper: '#ef0c75',
   logoLower: '#fe620b',
   grey: '#5a4c53',
   pink: '#ed0978',
   pinkBackground: '#8b0546',
   darkBlue: '#0f1222',

};